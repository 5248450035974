import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Life’s a journey. You have happy days and sad days. You have days where everything looks bright and other where you would give up on everything you are doing at the moment.
That’s why motivation will help you to get started, but discipline is what keep you going on.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2048/1*4M61qLumlofC7RQt0F_Chg.png",
        "alt": "Image"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`Did you read `}<a parentName="p" {...{
          "href": "/blog/4-selfdiscipline-hacks-to-hack-your-life"
        }}>{`my article about Discipline`}</a>{`?`}</p>
    </blockquote>
    <p>{`We have so much to do in our daily life. We get overwhelmed with opportunities.`}</p>
    <p>{`In one side, it is a good thing. It means that you have multiple opportunities in your plate and you just need to decide which one you want to pursue.`}</p>
    <p>{`It also means that your focus on the important things could be pulled away from you easily.`}</p>
    <p>{`I have 3 points that I think about every single morning: Vision, Happiness and Health.`}</p>
    <h2>{`Keep focus on vision`}</h2>
    <p>{`The difference from successful people and not successful people is that unsuccessful people usually give up.`}</p>
    <p>{`People wake up every morning with doubts, fears. Afraid to lose.
And in life, you will lose. Often.`}</p>
    <p>{`That’s why you need to keep focus on your Northstar. Your vision.`}</p>
    <p>{`My Northstar is focused in where I want to be 10, 20 years from now in different areas: private life, professional life, health and fitness.`}</p>
    <p>{`It helps me to stay focused during these darks days where everything looks falling apart and you want to give up. Look to your Northstar and keep pushing forward!`}</p>
    <p>{`You need a clear vision on where you want to go.`}</p>
    <p>{`Stay focused on the vision and execute daily on the most important things. As `}<a parentName="p" {...{
        "href": "https://www.garyvaynerchuk.com/"
      }}>{`Gary Vaynerchuk`}</a>{`, the CEO of `}<a parentName="p" {...{
        "href": "https://vaynermedia.com/"
      }}>{`VaynerMedia`}</a>{`, says: Clouds and Dirt`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/Y00u802Btps" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <h2>{`Keep focus on your happiness`}</h2>
    <p>{`As I said, life is a journey. We hope for an happy journey.`}</p>
    <p>{`Everyday, I ask this simple question: `}<b>{`What do I need in order to be happy?`}</b></p>
    <p>{`Using that simple question, you can easily map tasks, goals and everything you will need to reach your happiness.`}</p>
    <blockquote>
      <p parentName="blockquote">{`What do I need in order to be happy?`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/7422/0*PyokqhSB73DrQpFa",
        "alt": "Image"
      }}></img></p>
    <h2>{`Keep focus on your health`}</h2>
    <p>{`Every morning, I checkup where I am with my health, sleep and fitness.`}</p>
    <p>{`It has been already one year since I made a big change with my health.
I was not doing anything to improve my health.`}</p>
    <p>{`I didn’t felt good.
I was eating crap food.
I was not sleeping right.
And I didn’t do anything to change.`}</p>
    <p>{`You don’t do anything until you DO. Until you give priority to your health.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1000/1*HVbJFse-S7_grBC7erktgg.jpeg",
        "alt": "Me"
      }}></img>{`
The best thing you can do for your life.`}</p>
    <p>{`I started to go to gym three times / week. I started to eat healthy. Counting my calories. Eating good food and focusing on my health and fitness progress.`}</p>
    <p>{`What about sleeping? I wake up at 4 a.m. every single day.`}</p>
    <b>Domenico, I need to sleep at least 7,8,9 hours.</b>
    <p>{`My answer is `}<b>{`“Go To Sleep Earlier!”.`}</b></p>
    <p>{`Plus, it does not really matter how much you sleep, it matters what you do when you are awake.`}</p>
    <p>{`You can have the life that you desire.`}</p>
    <p>{`Everyday, It is important that you focus on your vision, on your happiness and your health.`}</p>
    <p>{`I changed my life focusing on these 3 points. Hope it can help to change yours for the better.`}</p>
    <p>{`Keep focus on your vision.
Keep focus on your happiness.
Keep focus on your health.`}</p>
    <p>{`And most important: keep going and do not give up.`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      